/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LastLoggedInUserMode } from './lastLoggedInUserMode';
import { GroupType } from './groupType';


export interface WlaClientModel { 
    id?: string | null;
    machineId?: string | null;
    organizationId?: number;
    groupID?: number;
    groupName?: string | null;
    name: string;
    groupType?: GroupType;
    domain: string;
    createdBy?: string | null;
    modifiedBy?: string | null;
    createdDate?: string;
    modifiedDate?: string;
    emergencyUser01?: string | null;
    emergencyUserPassword01?: string | null;
    emergencyUser02?: string | null;
    emergencyUserPassword02?: string | null;
    lastLoggedInUserMode?: LastLoggedInUserMode;
    enableConsole?: boolean;
    enableRDP?: boolean;
    isActive?: boolean;
    enforceMFA?: boolean;
}

