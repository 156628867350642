import {
  Directive,
  ElementRef,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/authentication/auth.service';
import { UserRoleLevels, UserRoles } from './constant.service';
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HasPermissionService {
  private roles = [];
  private targetRole = UserRoles.User;

  constructor(private authService: AuthService) {}

  async ngOnInit() {}

  public allowAccess(val: UserRoles[]) {
    this.roles = val;
    return this.checkPermission();
  }

  public async allowAccessAsync(
    val: UserRoles[],
    route: ActivatedRouteSnapshot
  ) {
    this.roles = val;
    return await this.checkPermissionAsync(route);
  }

  private checkPermission() {
    let hasPermission = false;
    const userType = this.userType;
    if (userType) {
      const found = this.roles.find(
        (x) => x.toUpperCase() === userType.toUpperCase()
      );
      hasPermission = !this.isNullOrUndef(found);
    }
    return hasPermission;
  }

  private async checkPermissionAsync(route: ActivatedRouteSnapshot) {
    let hasPermission = false;
    const user = await this.authService.getUser();
    if (this.authService.authRequired) {
      // Extract the route path and parameters from the snapshot
      const routePath = this.buildRouteFromSnapshot(route); // Construct the full path
      const queryParams = route.queryParams; // Extract query parameters
      sessionStorage.setItem(
        'auth-redirect',
        JSON.stringify({
          routePath,
          queryParams,
        })
      );
    }
    const userType = user?.profile?.UserType;
    if (userType) {
      const found = this.roles.find(
        (x) => x.toUpperCase() === userType.toUpperCase()
      );
      hasPermission = !this.isNullOrUndef(found);
    }
    return hasPermission;
  }

  get userType(): string {
    return this.authService.user?.profile?.UserType;
  }

  private isNullOrUndef(value: any) {
    return value === null || value === undefined;
  }

  allowAccessForTarget(val: UserRoles): boolean {
    this.targetRole = val;
    // levels for current and target user roles
    let currentuserlevel = UserRoleLevels[this.userType.toLowerCase()];
    let targetuserlevel = UserRoleLevels[this.targetRole.toLowerCase()];

    if (
      currentuserlevel &&
      targetuserlevel &&
      currentuserlevel >= targetuserlevel
    ) {
      return true;
    }

    return false;
  }

  private buildRouteFromSnapshot(snapshot: ActivatedRouteSnapshot): string[] {
    const fullPath = this.getFullPathFromSnapshot(snapshot); // Get full path with parameters replaced
    return fullPath.split('/'); // Return the full path as an array of segments
  }

  // Helper to recursively get the full path, including children
  private getFullPathFromSnapshot(snapshot: ActivatedRouteSnapshot): string {
    // Extract the current path and replace dynamic parameters
    const currentPath =
      snapshot.routeConfig?.path
        ?.split('/')
        .map((segment) =>
          segment.startsWith(':')
            ? snapshot.params[segment.substring(1)] || 'null'
            : segment
        )
        .join('/') || ''; // Replace parameters or use 'null' if missing

    // Recursively process children
    const childPaths = snapshot.children.map((child) =>
      this.getFullPathFromSnapshot(child)
    );

    // Combine the current path with child paths
    return [currentPath, ...childPaths].filter(Boolean).join('/');
  }
}
