import { Component, Input, OnInit } from '@angular/core';
import { ConstantService } from 'src/app/services/common/constant.service';

@Component({
  selector: 'app-row-detail',
  templateUrl: './row-detail.component.html',
  styleUrls: ['./row-detail.component.scss'],
})
export class RowDetailComponent implements OnInit {
  @Input() activity;

  constructor(public constantService: ConstantService) {}

  ngOnInit(): void {}

  isObject(val): boolean {
    return val != null && typeof val === 'object';
  }
}
