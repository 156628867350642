import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AgentService } from './api/agent.service';
import { ApplicationSettingService } from './api/applicationSetting.service';
import { AttributeMappingService } from './api/attributeMapping.service';
import { AttributeMetadataService } from './api/attributeMetadata.service';
import { DepartmentService } from './api/department.service';
import { DeploymentDetailService } from './api/deploymentDetail.service';
import { ExpressionService } from './api/expression.service';
import { GroupService } from './api/group.service';
import { GroupApplicationService } from './api/groupApplication.service';
import { GroupDirectoryService } from './api/groupDirectory.service';
import { LicenceService } from './api/licence.service';
import { LoggerSettingService } from './api/loggerSetting.service';
import { MessageTemplateService } from './api/messageTemplate.service';
import { MspService } from './api/msp.service';
import { OrganizationService } from './api/organization.service';
import { OrganizationApplicationService } from './api/organizationApplication.service';
import { OrganizationDirectoryService } from './api/organizationDirectory.service';
import { OrganizationQuestionSecretService } from './api/organizationQuestionSecret.service';
import { OrganizationSettingService } from './api/organizationSetting.service';
import { RadiusClientService } from './api/radiusClient.service';
import { RadiusMappingService } from './api/radiusMapping.service';
import { ReportsService } from './api/reports.service';
import { SafeLocationsService } from './api/safeLocations.service';
import { SecuritySettingService } from './api/securitySetting.service';
import { SyncServiceHistoryService } from './api/syncServiceHistory.service';
import { SyslogClientService } from './api/syslogClient.service';
import { TenantCleanupService } from './api/tenantCleanup.service';
import { ThemeService } from './api/theme.service';
import { VersionService } from './api/version.service';
import { WindowsLogonService } from './api/windowsLogon.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
