/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassbackAttribute } from './passbackAttribute';
import { AllowedGroupInfo } from './allowedGroupInfo';
import { RadiusDirectoryInfo } from './radiusDirectoryInfo';
import { AuthenticationType } from './authenticationType';


export interface RadiusClientModel { 
    radiusClientId?: number;
    organizationId?: number;
    mnemonic?: string | null;
    friendlyName: string;
    ipAddress: string;
    sharedSecret: string;
    port?: number | null;
    isAuthenticatePasscodeOnly?: boolean;
    isAuthenticateMFA?: boolean;
    authenticationType?: AuthenticationType;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    overwriteUsername?: string | null;
    trustedNetworks?: string | null;
    blockedNetworks?: string | null;
    dataAllowedGroups?: Array<AllowedGroupInfo> | null;
    dataPassbackAttribute?: PassbackAttribute;
    dataOtherAttributes?: RadiusDirectoryInfo;
    defaultDomain?: string | null;
    passbackAttribute?: string | null;
    otherAttributes?: string | null;
    allowedGroups?: string | null;
    oldVendorId?: number;
    messageAuthenticator?: boolean;
    agentID?: string | null;
}

