import { Injectable, isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { environment } from 'src/environments/environment';
import { OrganizationModel, SecuritySettingModel } from '../organization';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  FormStyle,
  getLocaleDayNames,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  public ToastError = { timeOut: 8000 };
  public TableLang: any;
  public AuthEnrollment = 'AuthEnrollment';
  public MultiFAEnrollment = 'MultiFAEnrollment';
  public readonly LoginAgent = 'LoginAgent';
  public readonly CloudMigration = 'CloudMigration';
  public readonly CloudMigrationMetadata = 'CloudMigrationMetadata';
  public readonly MaintenanceWindow = 'MaintenanceWindow';
  public readonly yubikeyCharMaxLength = 32;
  public readonly yubikeyCharMinLength = 12;
  public readonly maxSizeApplicationLogoInMB = 2;
  public readonly mfa = 'MFA';
  public readonly claims: string[] = [
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    'https://schemas.securenvoy.com/phone',
    'https://schemas.securenvoy.com/cell',
    'https://schemas.securenvoy.com/fax',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress',
    'https://schemas.securenvoy.com/pobox',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince',
    'https://schemas.securenvoy.com/city',
    'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode',
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/employeeid',
    'https://schemas.securenvoy.com/department',
    'https://schemas.securenvoy.com/jobtitle',
    'https://schemas.securenvoy.com/employeetype',
    'https://schemas.securenvoy.com/workcountry',
  ];
  public readonly allowAttempts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public readonly allowAttemptsDurations = [1, 5, 10, 15, 20, 30, 60];
  public readonly temporaryBlockMinutes = [0, 15, 30, 45, 60];
  public readonly minimumPasswordLengths = [8, 10, 12];
  public readonly hardwareTokenTimes = [30, 60];
  public readonly adminValidationDurations = [0, 15, 30, 60, 120, 240, 480];
  public readonly userAutoLockInactiveDays = [30, 60, 90, 120, 150, 180];

  public readonly dataTypes = ['string', 'number', 'date'];
  public readonly tonTypes = ['AlphaNumeric', 'ShortNumber', 'MSISDN'];
  public readonly dcsTypes = ['Text', 'GSM', 'Binary', 'UCS2'];
  public readonly priorityTypes = ['HIGH', 'NORMAL', 'LOW'];
  public readonly delimiters = [
    {
      displayName: 'Comma',
      value: ',',
    },
    {
      displayName: 'Tab',
      value: '\t',
    },
    {
      displayName: 'Semicolon',
      value: ';',
    },
    {
      displayName: 'Space',
      value: ' ',
    },
    {
      displayName: 'Pipe',
      value: '|',
    },
  ];

  public attributeTypes = [
    {
      id: 'string',
      value: 'string',
    },
    {
      id: 'integer',
      value: 'integer',
    },
    {
      id: 'octet',
      value: 'octet',
    },
    {
      id: 'tagged-integer',
      value: 'tagged-integer',
    },
    {
      id: 'tagged-string',
      value: 'tagged-string',
    },
    {
      id: 'ipaddr',
      value: 'ipaddr',
    },
    {
      id: 'date',
      value: 'date',
    },
  ];

  public authenticationTypes = [
    {
      id: 'SecondFactorAuth',
      value: 'Secondary Factor',
      isChecked: false,
      isDisabled: false,
      isMain: true,
    },
    {
      id: 'SecondFactorEnforce',
      value: 'Method Enforcement',
      isChecked: false,
      isDisabled: false,
      isSubType: true,
      parent: 'SecondFactorAuth',
    },
    { id: 'PassCode', value: 'PassCode', isChecked: false, isDisabled: false },
    {
      id: 'PassCodeSMS',
      value: 'SMS',
      isChecked: false,
      isDisabled: false,
      isSubType: true,
      parent: 'PassCode',
    },
    {
      id: 'PassCodeEmail',
      value: 'Email',
      isChecked: false,
      isDisabled: false,
      isSubType: true,
      parent: 'PassCode',
    },
    {
      id: 'Push',
      value: 'Push / SoftToken',
      isChecked: false,
      isDisabled: false,
    },
    {
      id: 'PushEnforce',
      value: 'Push Enforcement',
      isChecked: false,
      isDisabled: false,
      isSubType: true,
      parent: 'Push',
    },
    {
      id: 'UserCode',
      value: 'Static Code',
      isChecked: false,
      isDisabled: false,
    },
    { id: 'Hardware', value: 'Hardware', isChecked: false, isDisabled: false },
    { id: 'Yubikey', value: 'Yubikey', isChecked: false, isDisabled: false },
    {
      id: 'FidoToken',
      value: 'FidoToken',
      isChecked: false,
      isDisabled: false,
    },
  ];
  public readonly passCodeAllowedTypes = ['AlphaNumeric', 'Numeric'];
  public readonly pushTimesInSeconds = [30, 40, 50, 60];
  public readonly userCodeMinCharLengths = [2, 4, 6, 8, 10];

  public readonly regexIpAddress =
    '^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$';
  public regexUserEmail =
    '^[^<>!=*!@#$%&*()=+^][^<>!=*!@#$%&*()=^]*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,30}$';

  public regexUserName = '^[a-zA-Z0-9._-]*$';
  public regexAlphaNumWithOutSpaceDot = '^[a-zA-Z0-9]*$';

  private currentTenant: OrganizationModel = null;

  settingId: string;
  showOrgName: string = 'SecurEnvoy';
  imageUrl =
    environment.filestore.indexOf('/api/') >= 0
      ? environment.filestore + '/Filestore/GetImageByID/'
      : environment.filestore + '/api/Filestore/GetImageByID/';

  public dayNames: string[];
  public monthNames: string[];

  constructor(private translate: TranslateService) {
    this.settingId = 'settingId';
    const tenantData = localStorage.getItem('tenant');
    this.currentTenant = tenantData
      ? JSON.parse(localStorage.getItem('tenant'))
      : null;

    this.translate.onLangChange.subscribe((x) => {
      this.dayNames = getLocaleDayNames(
        x.lang,
        FormStyle.Standalone,
        TranslationWidth.Wide
      ).map((x) => x.toString());
      this.monthNames = getLocaleMonthNames(
        x.lang,
        FormStyle.Standalone,
        TranslationWidth.Wide
      ).map((x) => x.toString());
    });
  }

  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: false,
    toolbarHiddenButtons: [['insertVideo', 'insertImage']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  public resetTenant() {
    localStorage.removeItem('tenant');
    this.currentTenant = null;
  }

  setImageUrl(data) {
    data.forEach((element) => {
      if (element.logoUrl.startsWith('http')) {
      } else if (!element.logoUrl.includes('assets/images')) {
        element.logoUrl = this.imageUrl + element.logoUrl;
      }
    });
  }

  getImageUrl(value: string) {
    if (value.startsWith('http')) {
      return value;
    } else if (!value.includes('assets/images')) {
      return this.imageUrl + value;
    }
    return value;
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  getTimestamp(date: string | number | Date) {
    if (date) {
      return format(this.getUTC(date), 'yyyy-MM-dd HH:mm:ss');
    }
    return '';
  }

  getDate(date: string | number | Date) {
    if (date) {
      return format(this.getUTC(date), 'yyyy-MM-dd');
    }
    return '';
  }

  getUTC(value) {
    let date: Date;
    if (value instanceof Date) {
      date = value;
    } else {
      date = new Date(value);
    }
    return !value?.includes('Z')
      ? date
      : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  keyDown(event: any) {
    return false;
  }

  detachObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  setTableLang() {
    this.TableLang = {
      sProcessing: this.translate.instant('Datatables.Processing'),
      sSearch: this.translate.instant('Datatables.Search'),
      sLengthMenu: this.translate.instant('Datatables.LengthMenu'),
      sInfo: this.translate.instant('Datatables.Info'),
      sInfoEmpty: this.translate.instant('Datatables.InfoEmpty'),
      sInfoFiltered: this.translate.instant('Datatables.InfoFiltered'),
      sInfoPostFix: this.translate.instant('Datatables.InfoPostFix'),
      sLoadingRecords: this.translate.instant('Datatables.LoadingRecords'),
      sZeroRecords: this.translate.instant('Datatables.ZeroRecords'),
      sEmptyTable: this.translate.instant('Datatables.EmptyTable'),
      oPaginate: {
        sFirst: this.translate.instant('Datatables.First'),
        sPrevious: this.translate.instant('Datatables.Previous'),
        sNext: this.translate.instant('Datatables.Next'),
        sLast: this.translate.instant('Datatables.Last'),
      },
      oAria: {
        sSortAscending: this.translate.instant('Datatables.SortAscending'),
        sSortDescending: this.translate.instant('Datatables.SortDescending'),
      },
    };
  }
  getImageFromServer(key: string) {
    const uri = sessionStorage.getItem(key);
    return uri == null
      ? null
      : this.imageUrl.replace(RegExp('^(?:.*?/api/){2}'), function (x) {
          return x.replace(RegExp('/api/' + '$'), '/');
        }) + uri;
  }

  setSessionStorage(data) {
    for (const property in data) {
      if (property && data?.[property] && ThemeEnum.hasOwnProperty(property)) {
        sessionStorage.setItem(property, data[property]);
      }
    }
  }

  getSessionStorage(key: string) {
    return sessionStorage.getItem(key) ?? null;
  }

  removeSessionStorage(data) {
    for (const property in data) {
      if (property && ThemeEnum.hasOwnProperty(property)) {
        sessionStorage.removeItem(property);
      }
    }
  }

  public get mspTenant(): any {
    return this.currentTenant;
  }

  getNewKey(oldKey) {
    if (!isNaN(oldKey)) {
      oldKey = (+oldKey + 1).toString();
    }
    const newKey = oldKey.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
      return str.toUpperCase();
    });
    const result = newKey.replace(
      /^([a-zA-Z])(.*)\.([^.]+)$/,
      function (match, grp1, grp2, grp3, offset, s) {
        return (
          grp1.toUpperCase() +
          grp2 +
          ' ' +
          grp3.replace(/^./, (str) => {
            return str.toUpperCase();
          })
        );
      }
    );
    return result;
  }

  public setMspTenant(tenant: OrganizationModel) {
    localStorage.setItem('tenant', JSON.stringify(tenant));
    this.currentTenant = tenant;
  }

  public getDefaultTenant() {
    if (environment.production) {
      const hostname = window.location.hostname;
      return hostname.includes('.') ? hostname.split('.')[0] : hostname;
    }
    return environment.organizationCode;
  }

  public getSubdomain(fromTenantSelector: boolean = true) {
    let subdomain = environment.organizationCode;
    if (environment.production && !environment.singleTenantMode) {
      let hostname = window.location.hostname;
      if (hostname.includes('.')) {
        hostname = hostname.split('.')[0];
      }
      subdomain = hostname;
    }

    if (!fromTenantSelector) {
      return subdomain;
    }
    return this.currentTenant?.mnemonic ?? subdomain;
  }

  encode(value) {
    if (value) {
      return btoa(value);
    }
  }

  decode(value) {
    if (value) {
      return atob(value);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getValues(form: FormGroup) {
    const values = {};
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];
      values[key] = currentControl.value;
    });
    return values;
  }

  setFormValue(formValues: any, configValues: any) {
    configValues.forEach((configValue) => {
      configValue.fields.forEach((config) => {
        if (config?.name && formValues[config.name]) {
          if (config?.value !== undefined) {
            config.value = formValues[config?.name];
            return;
          }
          if (config?.readonlyValue !== undefined) {
            config.readonlyValue = formValues[config?.name];
            return;
          }
        }
      });
    });

    return configValues;
  }

  generateRandomPasswordSelection(securitySettings: SecuritySettingModel) {
    let length = 8 || securitySettings.passwordMinLength;
    let passwordLength = this.randomIntFromInterval(length, length + 2);
    let numberChars = securitySettings.pwdMustIncludeNumber ? '0123456789' : '';
    let upperChars = securitySettings.pwdMustIncludeUppercase
      ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      : '';
    let lowerChars = securitySettings.pwdMustIncludeLowercase
      ? 'abcdefghijklmnopqrstuvwxyz'
      : '';
    let specialChars = securitySettings.pwdMustIncludeSpecialCharacter
      ? "(!@#$%^&*()_{}[]+=|'"
      : '';
    let allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    if (window.crypto && window.crypto.getRandomValues) {
      return this.shuffleArray(
        randPasswordArray.map(function (x) {
          return x[
            Math.floor(
              (window.crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                x.length
            )
          ];
        })
      ).join('');
    } else {
      return this.shuffleArray(
        randPasswordArray.map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
      ).join('');
    }
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  //generate random number in the range (min and max included)
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  saveTableState(settings, data) {
    const stateData = { order: data.order, length: data.length };
    localStorage.setItem(
      `tableState-${settings.sTableId}`,
      JSON.stringify(stateData)
    );
  }

  loadTableState(settings) {
    try {
      const data = JSON.parse(
        localStorage.getItem(`tableState-${settings.sTableId}`)
      );
      data.time = new Date();
      return data;
    } catch (e) {
      return {};
    }
  }

  public getOrdinalSuffix(value: string) {
    const lang = this.translate.currentLang;
    switch (lang) {
      case 'de':
        return this.getGermanOrdinalSuffix(value);
      case 'es':
        return this.getSpanishOrdinalSuffix(value);
      case 'en':
        return this.getEnglishOrdinalSuffix(value);
      case 'fr':
        return this.getFrenchOrdinalSuffix(value);
      default:
        return this.getEnglishOrdinalSuffix(value);
    }
  }

  private getEnglishOrdinalSuffix(value: string) {
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === '1') {
        return 'th';
      }
    }

    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case '1':
        return 'st';
      case '2':
        return 'nd';
      case '3':
        return 'rd';
      default:
        return 'th';
    }
  }

  private getGermanOrdinalSuffix(value: string) {
    return '.';
  }

  private getSpanishOrdinalSuffix(value: string) {
    return '.\u1D43';
  }

  private getFrenchOrdinalSuffix(value: string) {
    if (value == '1') {
      return 're';
    }
    return 'e';
  }

  getPdfFromHttpBlob(fileName: string, blob: any) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(newBlob, fileName);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}

// tslint:disable-next-line: no-namespace
export namespace AuthenticationMethod {
  export function values(userFlag?: boolean) {
    const authenticationTypeCheck = ['values', 'updateValues'];
    if (userFlag) {
      authenticationTypeCheck.push(AuthenticationMethod.Push);
      return Object.keys(AuthenticationMethod).filter(
        (type) => isNaN(<any>type) && !authenticationTypeCheck.includes(type)
      );
    } else {
      return Object.keys(AuthenticationMethod).filter(
        (type) => isNaN(<any>type) && !authenticationTypeCheck.includes(type)
      );
    }
  }

  export function updateValues(removeKey?: string[], userFlag?: boolean) {
    const authenticationTypeCheck = ['values', 'updateValues'];
    if (userFlag) {
      authenticationTypeCheck.push(AuthenticationMethod.Push);
      if (removeKey.length > 0) {
        removeKey.push(...authenticationTypeCheck);
        return Object.keys(AuthenticationMethod).filter(
          (type) => isNaN(<any>type) && !removeKey.includes(type)
        );
      } else {
        return Object.keys(AuthenticationMethod).filter(
          (type) => isNaN(<any>type) && !authenticationTypeCheck.includes(type)
        );
      }
    } else {
      if (removeKey.length > 0) {
        removeKey.push(...authenticationTypeCheck);
        return Object.keys(AuthenticationMethod).filter(
          (type) => isNaN(<any>type) && !removeKey.includes(type)
        );
      } else {
        return Object.keys(AuthenticationMethod).filter(
          (type) => isNaN(<any>type) && !authenticationTypeCheck.includes(type)
        );
      }
    }
  }
}

export enum UserRoles {
  User = 'user',
  HelpDeskUser = 'helpdeskuser',
  AdminUser = 'adminuser',
  MspAdmin = 'mspadmin',
  SuperAdmin = 'superadmin',
}

export enum Permissions {
  NoAccess = 0,
  ReadOnly = 1,
  Full = 2,
}

export enum AuthenticationMethod {
  Password = 'Password',
  PassCode = 'PassCode',
  Push = 'Push',
  Yubikey = 'Yubikey',
  UserCode = 'UserCode',
  Hardware = 'Hardware',
}

export enum AuthenticationMethodSettings {
  PushEnforce = 'PushEnforce',
}

export enum PassCodeSourceType {
  Sms = 'Sms',
  Email = 'Email',
}

export enum PassCodeSource {
  PassCodeSMS = 'PassCodeSMS',
  PassCodeEmail = 'PassCodeEmail',
}

export enum ConfigurationSettingsEnum {
  gsuite = 'Google Workspace',
  azureDirectory = 'Microsoft Entra ID',
  activeDirectory = 'Active Directory',
  template = 'template',
  gateway = 'gateway',
  orgSettings = 'orgSettings',
  MFA = 'MFA',
  loggerSettings = 'loggerSettings',
  authEnrollment = 'authEnrollment',
  wla = 'wla',
  radius = 'radius',
  hardwareToken = 'HardwareToken',
  maxFailedLoginAttempts = 'MaxFailedLoginAttempts',
  temporaryUserLockedPeriod = 'TemporaryUserLockedPeriod',
  usernameMaskingEnabled = 'UsernameMaskingEnabled',
  lock = 'lock',
  userCodeFormat = 'UserCodeFormat',
  disablePrimaryFactory = 'DisablePrimaryFactory',
  pinProtectSoftToken = 'PinProtectSoftToken',
  Configurations = 'Configurations',
  Notification = 'Notification',
  MinimumPasswordExpiryNumberOfDays = 'MinimumPasswordExpiryNumberOfDays',
  MaximumPasswordExpiryNumberOfDays = 'MaximumPasswordExpiryNumberOfDays',
  DisablePasswordExpiryDays = 'DisablePasswordExpiryDays',
  MultiFAEnrollment = 'MultiFAEnrollment',
  SSOConfiguration = 'SSOConfiguration',
  Location = 'Location',
  SyncedUsers = 'SyncedUsers',
  AdminValidationEnabled = 'AdminValidationEnabled',
  AdminValidationDuration = 'AdminValidationDuration',
  ServerCountryCode = 'ServerCountryCode',
  AnomalyLoginNotification = 'AnomalyLoginNotification',
  MobileVerificationCheckingFrequency = 'MobileVerificationCheckingFrequency',
  UserAutoLockEnabled = 'UserAutoLockEnabled',
  UserAutoLockInactiveDays = 'UserAutoLockInactiveDays'
}

export enum HomeQueryStringEnum {
  error = 'error',
}

export enum SecretQuestionEnum {
  mustAskQuestionSecret = 'MustAskQuestionSecret',
  mustConfigureQuestionSecret = 'MustConfigureQuestionSecret',
}

export enum OrganizationTypeEnum {
  normal = 'Normal',
  msp = 'MSP',
}

export enum ThemeEnum {
  mainLogo = 'mainLogo',
  signinLogo = 'signinLogo',
  loginBackground = 'loginBackground',
  signupBackground = 'signupBackground',
  homeBackground = 'homeBackground',
  primaryColor = 'primaryColor',
  secondaryColor = 'secondaryColor',
  profileLogo = 'profileLogo',
  favicon = 'favicon',
  signOutBackground = 'signOutBackground',
  resetBackground = 'resetBackground',
}

export enum AppTypeEnum {
  configured = 'configured',
  notConfigured = 'not-configured',
}

export enum themeType {
  Web = 'web',
  Mobile = 'mobile',
}

export enum SubmitEnum {
  Save = 'save',
  Cancel = 'cancel',
  Delete = 'delete',
  Mapping = 'mapping',
}

export enum ApplicationEnum {
  gsuite = 'gsuite',
  activeDirectory = 'activeDirectory',
  office365 = 'Office 365',
  salesforce = 'Salesforce',
  googlegsuit = 'Google G-Suite',
}

export enum MultiAuthenticationMethod {
  SecondFactorAuth = 'SecondFactorAuth',
}
export enum SecondFactorAuthSourceEnum {
  SecondFactorEnforce = 'SecondFactorEnforce',
}

export enum DirectoryStatusEnum {
  connected = 'Connected',
  configured = 'Configured',
  notConfigured = 'Not Configured',
  notAuthenticated = 'Not Authenticated',
  reAuthenticate = 'Re Authenticate',
}

export enum DirectoryEnum {
  google = 'Google Directory',
  ad = 'Active Directory',
  ldap = 'LDAP',
  azure = 'Microsoft Entra ID',
}

export enum GatewaykeyEnum {
  email = 'EMail',
  sms = 'SMS',
  googlefirebase = 'GoogleFirebasePush',
  applepush = 'ApplePush',
  smtpemail = 'SMTPEmail',
  cmsms = 'CMSMS',
  twiliosms = 'TwilioSMS',
  aqlsmslegacy = 'AQLSMSLegacy',
  googlefirebasepush = 'GoogleFirebasePush',
  linkMobility = 'LinkMobility',
  awssms = 'AWSSMS',
}

export enum GroupsEnum {
  SuperAdmin = 'superadmin',
  Administrators = 'administrators',
  User = 'user',
  Everyone = 'everyone',
  MSP = 'msp',
  MspAdmin = 'mspadmin',
}

export enum DefaultTemplateColor {
  defaultColor = '#22BCE5',
  defaultPrimaryColor = '#342f89',
}

export const UserRoleLevels = {
  superadmin: 16,
  mspadmin: 8,
  adminuser: 4,
  helpdeskuser: 2,
  user: 1,
};

export enum MapTypeEnum {
  both = 'Both',
  upStream = 'UpStream',
  downStream = 'DownStream',
  notMapped = 'NotMapped',
}
